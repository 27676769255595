import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/header/hero'
import Container from '../components/container'
// import partners from '../images/partners-donations-cover.jpg'
import Newsletter from '../components/footer/newsletter'
import Slider from 'react-slick'
import '../components/slick/slick.scss';
import '../components/slick/slick-theme.scss';

const text_truncate = (str, length, ending) => {
  if (length == null) {
    length = 450;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

export default ({data}) => {
  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    centerMode: true,
    // centerPadding: 0,
    variableWidth: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          // variableWidth: false,
        }
      }
    ],
  };
  return (
    <Layout>
      <SEO title={data.wordpressPage.yoast_title} meta={data.wordpressPage.yoast_meta} path={data.wordpressPage.slug}/>
      <Hero mode="default"
        title={data.wordpressPage.acf.header_text}
        content={data.wordpressPage.acf.header_content}
        image={data.wordpressPage.featured_media.localFile.childImageSharp.fluid}
        button={
          {
            title: typeof data?.wordpressPage?.acf?.header_button?.title !== 'undefined' ? data.wordpressPage.acf.header_button.title : '',
            url: typeof data?.wordpressPage?.acf?.header_button?.url !== 'undefined' ? data.wordpressPage.acf.header_button.url : '',
            target: typeof data.wordpressPage.acf.header_button.target !== 'undefined' ? data.wordpressPage.acf.header_button.target : '',
          }
        }
      />
      <div className="about">
        <Container>
          <div className="contentEditorBlock" dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}/>
        </Container>
        <div className="boardSlider">
          <h3 className="title">Recipients</h3>
          <Slider {...settings}>
            {
              data.allWordpressWpAwardees.edges.map((n, i) => {
                return (
                  <div>
                    <BackgroundImage tag="div" className="slickSlideBG" fluid={n.node.featured_media.localFile.childImageSharp.fluid}>
                      <div className="slickSlideOverlay">
                        <h4>{n.node.title}</h4>
                        <h5>Recipient</h5>
                        <div className="bio" dangerouslySetInnerHTML={{ __html: text_truncate(n.node.content) }} />
                      </div>
                    </BackgroundImage>
                  </div>
                )
              })
            }
          </Slider>
        </div>
      </div>

      <Newsletter />
    </Layout>
  )
}
export const pageQuery = graphql`
query awardeesQuery {
  wordpressPage(wordpress_id: {eq: 588}) {
    acf {
      header_content
      header_text
      header_button {
        url
        title
        target
      }
    }
    content
    title
    slug
    yoast_meta {
      name
      property
    }
    yoast_title
    featured_media {
      source_url
      localFile {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1980) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
  allWordpressWpAwardees(filter: {categories: {elemMatch: {slug: {eq: "scholarship-awardee"}}}}) {
    edges {
      node {
        featured_media {
          source_url
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        title
        content
      }
    }
  }
}

`